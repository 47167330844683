@use "./styles/partials/global" as *;
@use "./styles/partials/mixins" as *;

.app {
	position: relative;
	padding: 0;
	height: 100vh;
	width: 100%;
	overflow-y: scroll;

	&__background {
		position: fixed;
		z-index: 1;
		padding: 0;
		height: 100vh;
		width: 100%;
		object-fit: cover;
		@include tablet {
			display: none;
		}

		&--wide {
			display: none;
			@include tablet {
				display: block;
			}
		}
	}
}
