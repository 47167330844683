@keyframes blink {
	0% {
		opacity: 0;
	}
	49% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
