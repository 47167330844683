@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/animations" as *;

.overflow-container {
	position: relative;
	overflow: hidden;
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
}

.single-project {
	position: relative;
	width: 100%;
	padding: 0;
	text-decoration: none;
	display: flex;
	width: 100%;
	height: 100%;

	&__thumbnail {
		z-index: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		object-fit: cover;
		object-position: top;
	}

	&__overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		background-color: #22222266;
	}

	&__badge {
		@include copy-sizing;
		position: absolute;
		color: $font-color2;
		z-index: 5;
		top: 0rem;
		left: 0rem;
		transform: translate(-5%, -50%);
		padding: 0.25rem 0.375rem;
		border-radius: 0.125rem;
		box-shadow: $darkshadow1;
		@include tablet {
			transform: translate(-20%, -50%);
			padding: 0.5rem;
		}

		&--fullstack {
			background: linear-gradient(90deg, #1a72e6, #8501bd);
		}
		&--frontend {
			background: linear-gradient(90deg, #1a72e6, #4192fc);
		}
		&--backend {
			background: linear-gradient(90deg, #7201bd, #8501bd);
		}
	}

	&__github {
		border-radius: 50%;
		overflow: hidden;
		position: absolute;
		display: flex;
		z-index: 5;
		top: 0;
		right: 0;
		transform: translate(12.5%, -50%);
		width: 2rem;
		height: 2rem;
		transition: transform 0.1s;
		&:hover {
			transform: translate(12.5%, -50%) scale(1.1);
		}
		@include tablet {
			width: 2.5rem;
			height: 2.5rem;
			transform: translate(50%, -50%);
			&:hover {
				transform: translate(50%, -50%) scale(1.1);
			}
		}
		@include desktop {
			width: 3.25rem;
			height: 3.25rem;
		}
	}

	&__top {
		@include flex-center-column;
	}

	&__content-container {
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	}

	&__link {
		color: #111111;
		width: 100%;
		height: 100%;
		@include flex-center-column;
		text-decoration: none;
		transition: transform 0.1s;
		&:hover {
			transform: scale(1.005);
		}
	}

	&__title {
		width: 100%;
		text-align: center;
		font-size: 1.5rem;
		font-weight: 600;
		margin: 0;
		padding: 0.5rem 1rem;
		background-color: $primary-color;
		border-bottom: 0.125rem solid #55555577;
		box-shadow: $darkshadow1;
		text-decoration: none;
		@include tablet {
			font-size: 1.75rem;
			padding: 0.75rem;
		}
	}

	&__copy {
		@include copy-sizing;
		display: flex;
		align-items: center;
		padding: 0.75rem 1rem;
		background-color: #00000088;
		color: $font-color2;
		margin: 1rem 0 0.5rem;
		text-align: center;
		align-self: center;
		width: 60%;
		border-radius: 0.5rem;
		@include tablet {
			padding: 1.25rem;
		}
	}
}
