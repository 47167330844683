@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.contact {
	@include section-padding;

	&__container {
		background-color: $primary-color;
		padding: 1rem;
		border-radius: 0.5rem;
		@include tablet {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-height: 15.75rem;
		}
		@include desktop {
			min-height: 17.5rem;
		}
	}

	&__copy {
		@include copy-sizing;
		margin: 0;
		margin-bottom: 2rem;
		color: #000;
	}

	&__links-container {
		display: flex;
		max-width: 25rem;
		margin: 0 auto;
		background-color: #aaaaaa99;
		box-shadow: $darkshadow1;
		padding: 1rem 0.5rem;
		border-radius: 0.5rem;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 1.2rem;
		@include tablet {
			max-width: 100%;
			align-items: stretch;
			padding: 1rem;
			gap: 1.5rem;
			flex-direction: row;
			justify-content: space-between;
		}
		@include desktop {
			padding: 1.25rem;
		}
	}

	&__link {
		box-shadow: $darkshadow1;
		@include flex-between-center;
		padding: 0.5rem;
		border-radius: 0.5rem;
		background-color: $primary-color;
		width: 100%;
		text-decoration: none;
		color: $font-color1;
		transition: transform 0.1s;
		max-width: 15rem;
		font-weight: 600;
		text-align: right;
		&:hover {
			transform: scale(1.05);
		}
		@include copy-sizing;
		@include tablet {
			flex-direction: column;
			text-align: center;
		}
		@include desktop {
			padding: 1rem;
			margin: 0;
			&:hover {
				transform: scale(1.025);
			}
		}
	}

	&__icon {
		margin-right: 1rem;
		width: 1.5rem;
		@include tablet {
			margin: 0;
			width: 2rem;
		}
		@include desktop {
			width: 2.5rem;
		}
	}
}
