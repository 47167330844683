@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.projects {
	@include section-padding;

	&__container {
		position: relative;
		@include flex-between-center;
		flex-direction: column;
		margin-top: 1rem;
		gap: 1rem;
		border-radius: 0.5rem;
		height: 65%;
		background-color: $primary-color;
		@include tablet {
			height: 75%;
			width: 100%;
			margin: 0 auto;
		}
	}

	&__button-container {
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__button {
		padding: 0;
		opacity: 0;
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		&--enabled {
			opacity: 1;
			@include button-styles;
		}
	}

	&__arrow {
		width: 50%;
		height: 1.5rem;
		clip-path: polygon(50% 0, 100% 100%, 0 100%);
		background-color: #9512d1;

		&--left {
			transform: rotate(-90deg);
		}

		&--right {
			transform: rotate(90deg);
		}
	}
}
