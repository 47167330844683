$darkshadow1: rgba(100, 100, 111, 0.459) 0.125rem 0.4375rem 0.75rem 0;

$primary-color: #eeeeee;
$accent1: #7201bd;
$accent2: #8501bd;

$font-color1: #111111;
$font-color2: #ffffff;

$code-color1: #0151ad;
$code-color2: #356e42;
$code-color3: #cf1111;
