@use "../partials/variables" as *;

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	color: $font-color1
}

main {
	position: relative;
	z-index: 2;
	width: 100%;
}

html,
body,
#root {
	height: 100%;
}
