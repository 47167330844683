@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.header {
	width: 100%;
	z-index: 3;
	position: fixed;
	top: 0;
	left: 0;
	background: linear-gradient(270deg, $accent1, $accent2);
	@include flex-between-center;
	padding: 0.5rem 1.5rem;
	box-shadow: $darkshadow1;
	@include tablet {
		background: linear-gradient(60deg, $accent1, $accent2);
		padding: 0.75rem;
		flex-direction: column;
		width: fit-content;
		height: 100%;
	}
	@include desktop {
		padding: 0.75rem;
	}

	&__title-container {
		@include tablet {
			display: flex;
			align-items: baseline;
			height: 33%;
		}
	}

	&__title {
		color: $font-color2;
		margin: 0;
		line-height: 100%;
	}

	&__subtitle {
		display: none;
		@include tablet {
			margin: 0 0 0 0.5rem;
			line-height: 100%;
			color: #999999;
			font-weight: 600;
			display: block;
		}
	}

	&__nav {
		margin: 0;
		height: 100%;
		@include tablet {
			height: 33%;
		}
	}

	&__nav-list {
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		gap: 1rem;
		padding: 0;
		margin: 0;
		list-style: none;
		@include tablet {
			flex-direction: column;
			justify-content: center;
			gap: 2rem;
		}
	}

	&__nav-item {
		display: flex;
		align-items: center;
	}

	&__nav-link {
		position: relative;
		height: 2rem;
		&:hover::after {
			padding: 0.4375rem;
			position: absolute;
			border-radius: 0.75rem;
			opacity: 0.9;
			bottom: -2.5rem;
			left: -0.75rem;
			color: $font-color2;
			background: linear-gradient(-45deg, rgb(108, 15, 151), #96068e);
			z-index: 6;
			@include tablet {
				transform: translateY(50%);
				padding: 0.625rem;
				font-size: 1.2rem;
				bottom: 50%;
				left: 3.75rem;
			}
		}
		&--projects {
			&:hover::after {
				content: "projects";
			}
		}
		&--about {
			&:hover::after {
				content: "about";
			}
		}
		&--contact {
			&:hover::after {
				content: "contact";
			}
		}
	}

	&__icon {
		position: relative;
		z-index: 0;
		width: 2rem;
		height: 2rem;
		padding: 0.25rem;
		border-radius: 0.5rem;
		border: 2px solid #000;
		filter: invert(100%);
		font-weight: 600;
		font-size: 0.9rem;
		text-decoration: none;
		transition: transform 0.1s;
		&:hover {
			transform: scale(1.2) translateY(5%);
		}
		@include tablet {
			width: 2.5rem;
			height: 2.5rem;
			&:hover {
				transform: scale(1.25) translateX(10%);
			}
		}
	}

	&__empty {
		display: none;
		@include tablet {
			display: block;
			height: 33%;
			width: 100%;
		}
	}
}
