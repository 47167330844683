@use "../../styles/partials/animations" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.about {
	@include section-padding;

	&__title-container {
		display: flex;
		gap: 0.75rem;
		@include tablet {
			gap: 1rem;
			flex-direction: column;
		}
		@include desktop {
			gap: 1.25rem;
		}
	}

	&__codebox {
		@include codebox;
		&:hover .about__hint {
			color: #444;
			transform: scale(1.05);
		}
	}

	&__code-label {
		display: block;
		width: 100%;
	}

	&__declaration {
		color: $code-color1;
	}

	&__function {
		color: $code-color3;
	}

	&__input-wrapper {
		display: inline-block;
		color: $code-color2;
	}

	&__input {
		cursor: pointer;
		animation: pulse 1.5s linear infinite;
		font-size: inherit;
		color: inherit;
		width: fit-content;
		padding: 0;
		outline: none;
		background-color: inherit;
		border: none;
		font-family: inherit;
		flex-grow: 0;
	}

	&__code {
		display: block;
		width: 100%;

		&--single-indent {
			padding-left: 1rem;
		}

		&--double-indent {
			padding-left: 2rem;
		}

		&--return {
			color: $code-color1;
		}
	}

	&__myPortfolio-container {
		padding: 1rem 0;
	}

	&__button-container {
		margin-top: 0.75rem;
		@include flex-between-center;
	}

	&__hint {
		color: #ccc;
		padding-right: 0.5rem;
		margin: 0;
		line-height: 100%;
		transition: transform, color, 0.1s;
	}

	&__submit {
		font-family: inherit;
		font-size: 1.5rem;
		align-self: flex-end;
		padding: 0.375rem 0.75rem;
		@include button-styles;
		@include tablet {
			padding: 0.375rem 1.75rem;
			font-size: 1.75rem;
		}
		@include desktop {
			font-size: 2rem;
		}
	}

	&__text-cursor {
		transform: translateY(0.2rem);
		content: "";
		display: inline-block;
		background-color: #111;
		margin-left: 0.1rem;
		width: 0.1rem;
		height: 1rem;
		animation: blink 1s infinite;
		@include tablet {
			height: 2rem;
		}
	}
}
