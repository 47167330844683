@use "./variables" as *;

@mixin tablet {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1280px) {
		@content;
	}
}

@mixin rotated-phone {
	@media (orientation: landscape) {
		@media (max-height: 400px) {
			@content;
		}
	}
}

@mixin section-padding {
	padding: 0 0.5rem;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include tablet {
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 0 3rem 0 calc(3rem + 83px);
	}
	@include desktop {
		padding: 0 10% 0 calc(10% + 81px);
	}
	@include rotated-phone() {
		min-height: 100vh;
		height: fit-content;
		padding: 5rem 3rem 2rem;
	}
}

@mixin copy-sizing {
	font-size: 0.8125rem;
	@include tablet {
		font-size: 1rem;
	}
	@include desktop {
		font-size: 1.25rem;
	}
}

@mixin flex-center-column {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@mixin flex-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin codebox {
	@include copy-sizing;
	width: 100%;
	color: #333;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	height: fit-content;
	background-color: $primary-color;
	font-family: monospace;
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
	border: 1px solid #ccc;
	box-shadow: $darkshadow1;
	@include tablet {
		padding: 1.25rem;
	}
	@include desktop {
		padding: 1.75rem;
	}
}

@mixin button-styles {
	background-color: $font-color2;
	border: none;
	border-bottom: 4px solid #aaaaaa;
	box-shadow: $darkshadow1;
	border-radius: 0.375rem;
	cursor: pointer;
	transition: transform 0.2s;
	&:hover {
		transform: scale(1.15) translateY(-5%);
	}
}
